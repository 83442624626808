import clsx from 'clsx';

import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

import { useAppContext } from '../../../AppContext';

import tags from '../../../../data/tags.json';

const LanguagesCard: React.FC = () => {

    const app = useAppContext();

    return (
        <Card id="languages" className="mb-3">
            <Card.Header className="border-0 pb-0 mt-1">
                <h2 className="h4">
                    <FontAwesomeIcon
                        icon={faCode}
                        size="sm"
                        className="text-secondary"
                    />
                    {" "}
                    <span>Linguagens e frameworks</span>
                </h2>
            </Card.Header>

            <Card.Body className="pt-0">
                <p className="mb-1">
                    Lista de algumas das Linguagens, frameworks e ferramentas que tenho conhecimentos.
                </p>

                <p className="mb-2">
                    <span>
                        Clique numa linguagem ou framework para filtrar os resultados na lista de projetos.
                    </span>
                </p>

                <p className="mt-2 mb-2 fw-bold">
                    {app.currentTag !== '' ? (
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                app.setCurrentTag('');
                            }}
                        >
                            Remover o filtro
                        </a>
                    ) : (
                        <span className="text-secondary">
                            (Nenhum filtro selecionado)
                        </span>
                    )}
                </p>

                {tags.map((tag, index) => (
                    <div key={index} className="mb-2">
                        <h5>{tag.name}:</h5>

                        {tag.lista.map((value, index) => (
                            <span key={`button-${index}`}>
                                <button
                                    type="button"
                                    className={clsx(
                                        "btn btn-sm rounded-pill m-1 fw-bold ",
                                        app.currentTag === value[0] ? 'btn-primary' : 'btn-outline-primary'
                                    )}
                                    onClick={() => app.setCurrentTag(value[0])}
                                >
                                    {value[1]}
                                </button>
                            </span>
                        ))}
                    </div>
                ))}
            </Card.Body>
        </Card>
    );
}

export default LanguagesCard;
