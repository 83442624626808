
const AppFooter: React.FC = () => {
    return (
        <footer className="d-flex flex-column align-items-center my-3">
            <a
                href="https://fabiopichler.net/"
                className="small link-secondary"
                target="_blank"
            >
                © 2023, Fábio Pichler
            </a>
        </footer>
    );
}

export default AppFooter;
