
import { Route, Routes } from 'react-router-dom';

import HomePage from '../pages/homePage/HomePage';
import ShowProjectPage from '../pages/showProjectPage/ShowProjectPage';
import ShowImagesPage from '../pages/showImagesPage/ShowImagesPage';

const AppRouter: React.FC = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={<HomePage />}
            />

            <Route
                path="/projeto/:id"
                element={<ShowProjectPage />}
            />

            <Route
                path="/imagens"
                element={<ShowImagesPage />}
            />
        </Routes>
    );
}

export default AppRouter;
