import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Badge, Card, Image } from 'react-bootstrap';
import { faFolderOpen, faRectangleList } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { Gallery, Item } from 'react-photoswipe-gallery';

import { useAppContext } from '../../../AppContext';

const MyButton = styled.button`
    background-color: rgb(240, 240, 240);
    transition: opacity 300ms;

    &:hover {
        opacity: 0.9;
    }

    & .image {
        max-width: 260px;
        max-height: 260px;
    }

    @media (min-width: 576px) {
        width: 140px;
        height: 140px;

        & .image {
            max-width: 100%;
            max-height: 100%;
        }
    }
`;

const ProjectsCard: React.FC = () => {

    const app = useAppContext();

    return (
        <Card id="projects">
            <Card.Header className="border-0 pb-0">
                <h2 className="h4 mt-1">
                    <FontAwesomeIcon
                        icon={faRectangleList}
                        size="sm"
                        className="text-secondary"
                    />
                    {" "}
                    <span>Projetos</span>
                </h2>
            </Card.Header>

            <Card.Body className="pt-0">
                <div>
                    <span>Lista com alguns dos meus projetos pessoais.</span>

                    <br />

                    <Link
                        to="/imagens"
                    >
                        Ver todas as imagens de todos os projetos
                    </Link>

                    {app.currentTag === '' ? (
                        <>
                            <div className="mb-2">
                                Você pode clicar numa linguagem ou framework logo a cima para filtrar os resultados.
                            </div>

                            <div className="fw-bold text-muted">
                                Exibindo
                                <span className="text-dark"> {app.currentProjects.length} </span>
                                projetos:
                            </div>
                        </>
                    ) : (
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between fw-bold text-muted mt-2">
                            <span>
                                <span>Exibindo</span>
                                <span className="text-dark"> {app.currentProjects.length} </span>
                                projetos de <span className="text-dark">{app.currentTag}</span>:
                            </span>

                            <a
                                href="#"
                                className="fw-bold"
                                onClick={e => {
                                    e.preventDefault();
                                    app.setCurrentTag('');
                                }}
                            >
                                Exibir todos
                            </a>
                        </div>
                    )}
                </div>

                {app.currentProjects.map((value, index) => (
                    <Fragment key={`project-${index}`}>
                        <div
                            className="my-4 my-sm-3 border-bottom"
                        />

                        <div className="d-flex flex-column flex-sm-row align-items-center text-center text-sm-start">
                            <Gallery>
                                <Item
                                    original={value.mainImage?.src}
                                    thumbnail={value.mainImage?.src}
                                    width={value.mainImage?.width}
                                    height={value.mainImage?.height}
                                >
                                    {({ ref, open }) => (
                                        <MyButton
                                            ref={ref as any}
                                            as={value.mainImage ? 'button' : 'span'}
                                            onClick={value.mainImage ? open : undefined}
                                            className="flex-shrink-0 d-flex justify-content-center align-items-center me-0 me-sm-3 p-0 border-0 mb-2 mb-sm-0"
                                        >
                                            <Image
                                                src={value.mainImage?.src ?? '/images/no-image.png'}
                                                className="image"
                                            />
                                        </MyButton>
                                    )}
                                </Item>
                            </Gallery>

                            <div>
                                <h3 className="h5 mb-1">
                                    <Link
                                        to={`/projeto/${value.id}`}
                                    >
                                        {value.name}
                                    </Link>
                                </h3>

                                <p className="mb-0">{value.description}</p>

                                <p className="mb-1 small">
                                    {value.url ? (
                                        <span><a href={value.url} target="_blank">Acessar</a> | </span>
                                    ) : null}

                                    {value.site ? (
                                        <span><a href={value.site} target="_blank">Site oficial</a> | </span>
                                    ) : null}

                                    {value.git ? (
                                        <span><a href={value.git} target="_blank">GitHub</a></span>
                                    ) : (
                                        <span className="text-secondary fst-italic">GitHub não disponível</span>
                                    )}
                                </p>

                                <p className="mb-0 small">
                                    {value.tags.map((tag, index) => (
                                        <Badge
                                            pill
                                            bg="secondary"
                                            key={`tag-${index}`}
                                            className="me-1"
                                        >
                                            {tag}
                                        </Badge>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </Fragment>
                ))}

                {app.currentProjects.length === 0 ? (
                    <div className="py-5 text-center h5">
                        <FontAwesomeIcon
                            icon={faFolderOpen}
                        />
                        {' '}
                        <span>Nenhum projeto listado no momento</span>
                    </div>
                ) : null}
            </Card.Body>
        </Card>
    );
}

export default ProjectsCard;
