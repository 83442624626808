import AboutCard from './aboutCard/AboutCard';
import LanguagesCard from './languagesCard/LanguagesCard';
import ProjectsCard from './projectsCard/ProjectsCard';

const HomePage: React.FC = () => (
    <main>
        <AboutCard />

        <LanguagesCard />

        <ProjectsCard />
    </main>
);

export default HomePage;
