import styled from 'styled-components';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Card, Image } from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';

import projects from '../../../data/projects.json';

const MyButton = styled.button`
    transition: opacity 300ms;

    &:hover {
        opacity: 0.9;
    }

    & .image {
        max-height: 768px;
    }
`;

const ShowProjectPage: React.FC = () => {

    const params = useParams<{ id: string }>();

    const [project, setProject] = useState<typeof projects[0] | null>(null);

    useEffect(() => {
        for (const project of projects) {
            if (project.id === params.id) {
                setProject(project);
                break;
            }
        }
    }, []);

    if (!project)
        return <div>Carregando...</div>;

    return (
        <main>
            <Card className="mb-3">
                <Card.Header className="border-0 pb-0">
                    <p className="mb-2">
                        {project.tags.map((tag, index) => (
                            <Badge
                                pill
                                bg="light"
                                text="dark"
                                key={`tag-${index}`}
                                className="me-1"
                            >
                                {tag}
                            </Badge>
                        ))}
                    </p>

                    <h2 className="h3 d-flex">
                        <span>{project.name}</span>
                    </h2>
                </Card.Header>

                <Card.Body className="pb-0 pt-0">
                    <p className="mb-2">{project.description}</p>

                    <p className="mb-3">
                        {project.url ? (
                            <span className="d-block">Acessar: <a href={project.url} target="_blank">{project.url.replace(/^https?:\/\//, '')}</a></span>
                        ) : null}

                        {project.site ? (
                            <span className="d-block">Site oficial: <a href={project.site} target="_blank">{project.site.replace(/^https?:\/\//, '')}</a></span>
                        ) : null}

                        <span className="d-block">
                            GitHub:{' '}

                            {project.git ? (
                                <span><a href={project.git} target="_blank">{project.git.replace(/^https?:\/\//, '')}</a></span>
                            ) : (
                                <span className="text-secondary fst-italic">Não disponível no momento</span>
                            )}
                        </span>
                    </p>

                    <div className="d-flex flex-column align-items-center">
                        {project.images.length === 0 ? (
                            <Image
                                src="/images/no-image.png"
                                className="image mb-3"
                                fluid
                            />
                        ) : null}

                        <Gallery>
                            {project.images.map((image, index) => (
                                <Item
                                    key={`item-image-${index}`}
                                    original={image.src}
                                    thumbnail={image.src}
                                    width={image.width}
                                    height={image.height}
                                >
                                    {({ ref, open }) => (
                                        <MyButton
                                            ref={ref as any}
                                            onClick={open}
                                            className="mb-3 p-0 border-0"
                                        >
                                            <Image
                                                src={image.src}
                                                className="image"
                                                fluid
                                            />
                                        </MyButton>
                                    )}
                                </Item>
                            ))}
                        </Gallery>
                    </div>
                </Card.Body>
            </Card>
        </main>
    );
}

export default ShowProjectPage;
