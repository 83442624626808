import styled from 'styled-components';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import AppFooter from './appFooter/AppFooter';
import AppHeader from './appHeader/AppHeader';
import AppRouter from './appRouter/AppRouter';

import { AppContext } from './AppContext';

import projects from '../data/projects.json';

const MyContainer = styled(Container)`
    max-width: 768px;
`;

const App: React.FC = () => {

    const { hash } = useLocation();

    const [currentTag, setCurrentTag] = useState('');
    const [currentProjects, setCurrentProjects] = useState<typeof projects>(projects);

    useEffect(() => {
        if (currentTag === '') {
            setCurrentProjects(projects);
        } else {
            const proj: typeof projects = [];

            for (const project of projects)
                if (project.tags.indexOf(currentTag) > -1)
                    proj.push(project);

            setCurrentProjects(proj);
        }

    }, [currentTag]);

    useEffect(() => {
        if (!hash)
            return;

        const element = document.getElementById(hash.substring(1));

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center'
            });
        }

    }, [hash]);

    return (
        <AppContext.Provider value={{ currentTag, setCurrentTag, currentProjects }}>
            <MyContainer fluid className="container-x-sm">
                <AppHeader />

                <AppRouter />

                <AppFooter />
            </MyContainer>
        </AppContext.Provider>
    );
}

export default App;
