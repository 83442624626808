import { createContext, useContext } from 'react';

import projects from '../data/projects.json';

export interface IAppContext {
    currentTag: string;
    setCurrentTag: (tag: string) => void;
    currentProjects: typeof projects;
}

export const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => useContext(AppContext)!;
