import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

const AboutCard: React.FC = () => {
    return (
        <Card id="about" className="mb-3">
            <Card.Header className="border-0 pb-0">
                <h2 className="h4 mt-1">
                    <FontAwesomeIcon
                        icon={faAddressCard}
                        size="sm"
                        className="text-secondary"
                    />
                    {" "}
                    <span>Sobre mim</span>
                </h2>
            </Card.Header>

            <Card.Body className="py-0">
                <p>
                    Me chamo Fábio, amo ciência e tecnologia, especialmente Astronomia, programação e eletrônica.
                    <br /><br />
                    Sou programador e estudo programação desde 2013 como autodidata e, no momento, ainda me enquadro como júnior por nunca ter trabalhado profissionalmente na área (estou em busca da minha primeira oportunidade).
                    <br /><br />
                    Normalmente tenho facilidade em aprender, e como desenvolvedor (web, mobile e desktop), possuo alguns conhecimentos e experiências nas linguagens C, C++, Java, JavaScript, TypeScript, PHP, HTML5, CSS, SQL e nos frameworks React.js, Spring Boot (Spring Framework), Laravel, Bootstrap, Node.js, React Native, Qt, assim como outras linguagens e frameworks.
                </p>
            </Card.Body>
        </Card>
    );
}

export default AboutCard;
