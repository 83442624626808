import styled from 'styled-components';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Header = styled.header`
`;

const MyCard = styled(Card)`
    .card-img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .avatar {
        box-shadow: 0 3px 6px rgb(0, 0, 0, 0.3);
        width: 60px;
        margin-top: calc(-60px / 2);

        @media (min-width: 380px) {
            width: 80px;
            margin-top: calc(-80px / 2);
        }

        @media (min-width: 576px) {
            width: 120px;
            margin-top: calc(-120px / 2);
        }
    }
`;

const AppHeader: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const navClickHandler = () => {
        window.scrollTo(0, 0);
    }

    return (
        <Header className="mb-3">
            <MyCard className="mb-3 mt-0 mt-sm-3">
                <Card.Img
                    className="card-img"
                    variant="top"
                    src="/images/cover.jpg"
                />

                <Card.ImgOverlay className="d-flex align-items-start">
                    {location.pathname !== '/' ? (
                        <button
                            className="btn btn-link link-light p-1"
                            onClick={() => navigate(-1)}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                size="lg"
                            />
                        </button>
                    ) : null}

                    <span className="flex-fill" />

                    <a
                        href="https://github.com/fabiopichler"
                        target="_blank"
                        className="me-3 link-light"
                    >
                        <FontAwesomeIcon
                            icon={faGithub}
                            size="xl"
                        />
                    </a>

                    <a
                        href="https://www.linkedin.com/in/fabio-pichler/"
                        target="_blank"
                        className="link-light"
                    >
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            size="xl"
                        />
                    </a>
                </Card.ImgOverlay>

                <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center pt-0 position-relative">
                    <Image
                        className="mb-3 avatar"
                        src="/images/avatar.jpg"
                        roundedCircle
                    />

                    <div style={{ maxWidth: 500, }}>
                        <h1 className="h3">Fábio Pichler</h1>

                        <p className="mb-1"><span className="fw-bold">Programador | Full Stack Developer</span> (PHP, Java, JavaScript, TypeScript, ReactJS, Spring, Laravel, C, C++, Qt, Node.js)</p>

                        <p className="mb-1 text-center">
                            <a
                                href="https://fabiopichler.net/"
                                className="small fw-bold"
                                target="_blank"
                            >
                                www.fabiopichler.net
                            </a>
                        </p>

                        <p className="mb-0 text-center">
                            <a
                                href="https://github.com/fabiopichler"
                                className="small fw-bold"
                                target="_blank"
                            >
                                GitHub
                            </a>

                            <span className="text-secondary"> | </span>

                            <a
                                href="https://www.linkedin.com/in/fabio-pichler/"
                                className="small fw-bold"
                                target="_blank"
                            >
                                LinkedIn
                            </a>
                        </p>
                    </div>
                </Card.Body>
            </MyCard>

            <Card className="mb-3">
                <Card.Body className="text-center small fw-bold">
                    <Link
                        to="/"
                        className="d-inline-block"
                        onClick={navClickHandler}
                    >
                        Início
                    </Link>

                    <span className="text-secondary"> | </span>

                    <Link
                        to={{
                            pathname: "/",
                            hash: "#about"
                        }}
                        className="d-inline-block"
                    >
                        Sobre
                    </Link>

                    <span className="text-secondary"> | </span>

                    <Link
                        to={{
                            pathname: "/",
                            hash: "#languages"
                        }}
                        className="d-inline-block"
                    >
                        Linguagens
                    </Link>

                    <span className="text-secondary"> | </span>

                    <Link
                        to={{
                            pathname: "/",
                            hash: "#projects"
                        }}
                        className="d-inline-block"
                    >
                        Projetos
                    </Link>

                    <span className="text-secondary"> | </span>

                    <Link
                        to="/imagens"
                        className="d-inline-block"
                        onClick={navClickHandler}
                    >
                        Todas as imagens
                    </Link>
                </Card.Body>
            </Card>
        </Header>
    );
}

export default AppHeader;
