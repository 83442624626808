import clsx from 'clsx';
import styled from 'styled-components';

import { Card, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { Gallery, Item } from 'react-photoswipe-gallery';

import projects from '../../../data/projects.json';
import { Link } from 'react-router-dom';

const MyButton = styled.button`
    transition: opacity 300ms;

    &:hover {
        opacity: 0.9;
    }

    & .image {
        max-height: 768px;
    }
`;

const ShowImagesPage: React.FC = () => (
    <main>
        <Card className="mb-3">
            <Card.Header className="border-0 pb-0">
                <h2 className="h4 mt-1">
                    <FontAwesomeIcon
                        icon={faImage}
                        size="sm"
                        className="text-secondary"
                    />
                    {" "}
                    <span>Imagens</span>
                </h2>
            </Card.Header>

            <Card.Body className="pt-0">
                <div className="mb-3">
                    Lista com as imagens de todos os projetos (clique nas imagens para expandir).
                </div>

                <Gallery>
                    {projects.map((project, index) => (
                        <div
                            key={`item-${index}`}
                            className={clsx('border-top mt-2 pt-3', {
                                'd-none': project.images.length === 0,
                            })}
                        >
                            <h4>
                                <Link
                                    to={`/projeto/${project.id}`}
                                >
                                    {project.name}
                                </Link>
                            </h4>

                            <div className="mb-3">{project.description}</div>

                            <div className="text-center">
                                {project.images.map((image, index) => (
                                    <Item
                                        key={`item-image-${index}`}
                                        original={image.src}
                                        thumbnail={image.src}
                                        width={image.width}
                                        height={image.height}
                                    >
                                        {({ ref, open }) => (
                                            <MyButton
                                                ref={ref as any}
                                                onClick={open}
                                                className="mb-3 p-0 border-0"
                                            >
                                                <Image
                                                    src={image.src}
                                                    className="image"
                                                    fluid
                                                />
                                            </MyButton>
                                        )}
                                    </Item>
                                ))}
                            </div>
                        </div>
                    ))}
                </Gallery>
            </Card.Body>
        </Card>
    </main>
);

export default ShowImagesPage;
